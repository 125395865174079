import React from 'react';
import type { AppProps } from 'next/app';
import { useEffect, useState } from 'react';
import NextNProgress from 'nextjs-progressbar';
import GoogleAnalytics from '../components/GoogleAnalytics';

import '../assets/styles/globals.sass';
import '../assets/typography';
import 'react-phone-input-2/lib/style.css';

import { AppContainer } from '../components/layout/AppContainer';
import { AuthProvider } from '../lib/auth';
import '../lib/stripe';
import { isMobile } from '../utils/utilsDevice';
import { MobileOnly } from '../components/common/MobileOnly';
import { getDefaultLanguage } from '../utils/utilsLanguage';

import { appWithI18Next, useSyncLanguage } from 'ni18n';
import { ni18nConfig } from '../ni18n.config';

const nProgressConfig = {
  color: '#300096',
  showOnShallow: true,
};

function App({ Component, pageProps }: AppProps) {
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  const locale = getDefaultLanguage();
  useSyncLanguage(locale);

  useEffect(() => {
    setIsMobileDevice(isMobile());
  }, []);

  if (!isMobileDevice) {
    return <MobileOnly />;
  }

  return (
    <AuthProvider>
      <NextNProgress {...nProgressConfig} />
      <AppContainer {...pageProps} component={Component} />
      {process.env.NEXT_PUBLIC_GA_TRACKING_ID && (
        <GoogleAnalytics
          GA_TRACKING_ID={process.env.NEXT_PUBLIC_GA_TRACKING_ID}
        />
      )}
    </AuthProvider>
  );
}

export default appWithI18Next(App, ni18nConfig);
