import { useTranslation } from 'react-i18next';
import RastelTitleIcon from '../../../assets/svg/rastel_title.svg';

const DEFAULT_COLOR = '#300096';

export const AppHero = ({ inverted = false }) => {
  const { t } = useTranslation();

  return (
    <div className="flex my-5 mx-auto flex-col items-center justify-center">
      <RastelTitleIcon
        fill={inverted ? 'white' : DEFAULT_COLOR}
        stroke={inverted ? 'white' : DEFAULT_COLOR}
      />
      <div
        className={`text-md mt-2 text-center ${
          inverted ? 'text-white' : 'text-primary'
        }`}
      >
        {/* @ts-ignore */}
        {t('appHeroSubtitle')}
      </div>
    </div>
  );
};
