import { gql } from '@apollo/client';

export const CurrentUserQuery = gql`
  query me {
    authenticatedItem {
      ... on User {
        id
        email
        phoneNumber
        name
        isPhoneNumberVerified
        walletBalance
        stripeVerificationSession {
          status
        }
        userParkings {
          id
          status
          parking {
            id
          }
        }
        completedQuests {
          id
          createdAt
          quest {
            id
            title
            reward
            description
            type
          }
        }
      }
    }
  }
`;
