import { ni18nConfig, DEFAULT_LANGUAGE } from '../ni18n.config';

export const LANG_LOCAL_STORAGE_KEY = 'lang';

export const getDefaultLanguage = () => {
  if (typeof window === 'undefined') return DEFAULT_LANGUAGE;

  const localStorageLanguage = localStorage.getItem(LANG_LOCAL_STORAGE_KEY);

  if (localStorageLanguage) return localStorageLanguage;

  const language = navigator.languages
    ? navigator.languages[0].split('-')[0]
    : navigator.language || DEFAULT_LANGUAGE;
  if (ni18nConfig.supportedLngs.includes(language)) {
    return language;
  }

  return DEFAULT_LANGUAGE;
};
