import MapIcon from '../assets/svg/map.svg';
import RequestLocationIcon from '../assets/svg/request_location.svg';
import ProfileIcon from '../assets/svg/profile.svg';

export const routes = [
  {
    id: 'map',
    name: 'Map',
    href: '/',
    icon: MapIcon,
  },
  {
    id: 'profile',
    name: 'Profile',
    href: '/profile',
    icon: ProfileIcon,
  },
];

export type BottomRouteType = {
  name: string;
  href: string;
  icon: React.ComponentType<{ className?: string }>;
};
