import { gql } from "@apollo/client";

export const RedeemForgotPasswordTokenMutation = gql`
  mutation redeemForgotPasswordTokenMutation(
    $email: String!
    $token: String!
    $password: String!
  ) {
    redeemUserPasswordResetToken(
      email: $email
      token: $token
      password: $password
    ) {
      ... on RedeemUserPasswordResetTokenResult {
        code
        message
      }
    }
  }
`;
