import { BottomRouteType, routes } from '../../../lib/routes';
import { useRouter } from 'next/router';
import { useAuth } from '../../../lib/auth';
import { useTranslation } from 'react-i18next';

const hideNavRoutes = ['/rewards/[id]', '/reward-claims/[id]'];
export const BottomNav = () => {
  const router = useRouter();
  const { currentUser } = useAuth();
  const { t } = useTranslation();

  if (hideNavRoutes.includes(router.pathname)) {
    return null;
  }

  const onRoutePress = (selectedRoute: BottomRouteType) => {
    if (!currentUser?.isPhoneNumberVerified) {
      return;
    }

    router.push(selectedRoute.href, undefined, { shallow: true });
  };

  return (
    <div className="h-bottom-nav sticky bottom-0 bg-white flex justify-around items-center border-t border-t-gray-dark z-bottom-nav">
      {routes.map((route, index) => {
        const isActiveRoute = route.href === router.route;

        return (
          <div
            key={index}
            className={`text-gray-dark flex flex-1 items-center justify-center text-xs flex-col-reverse transition-colors duration-500 ${isActiveRoute && 'text-primary font-bold'
              }`}
            onClick={() => onRoutePress(route)}
          >
            <div>{t(route.id)}</div>
            <route.icon
              className={`${isActiveRoute && 'text-primary stroke-primary'
                } w-6 h-6 duration-500 transition-colors`}
            />
          </div>
        );
      })}
    </div>
  );
};
