import Lottie from 'react-lottie';
import * as mobileOnlyAnimationData from '../../../assets/lottie/mobile-only.json';
import { AppHero } from '../AppHero';
import { QRCodeSVG } from 'qrcode.react';

export const MobileOnly = () => {
  return (
    <div className="bg-primary flex flex-col h-screen py-24">
      <div>
        <AppHero inverted />
      </div>
      <div className="ml-6 mt-12 flex justify-center">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: mobileOnlyAnimationData,
          }}
          height={321}
          width={321}
        />
      </div>
      <div className="text-lg w-2/3 mx-auto text-center text-white flex flex-col gap-2">
        <p>This app is only available on mobile devices. </p>
        <p>
          {`In order to switch, scan the QR code below with your
          phone's camera`}
        </p>
      </div>
      <div className="flex justify-center mt-12">
        {' '}
        <QRCodeSVG
          className="border-[8px] border-white rounded-xl shadow-2xl"
          value={`https://app.rastel.io`}
          imageSettings={{
            src: '/qrCodeLogo.png',
            x: undefined,
            y: undefined,
            height: 36,
            width: 36,
            excavate: true,
          }}
          size={256}
        />{' '}
      </div>
    </div>
  );
};
