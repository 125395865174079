import { gql } from '@apollo/client';

export const RegisterRiderMutation = gql`
  mutation registerRider(
    $name: String!
    $email: String!
    $password: String!
    $phoneNumber: String!
    $isTermsAccepted: Boolean!
    $isOfLegalAge: Boolean!
    $companyId: ID
  ) {
    registerRider(
      name: $name
      email: $email
      password: $password
      phoneNumber: $phoneNumber
      isTermsAccepted: $isTermsAccepted
      isOfLegalAge: $isOfLegalAge
      companyId: $companyId
    ) {
      email
    }
  }
`;
