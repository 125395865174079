import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../lib/auth';
import { Loader } from '../../Loader';
import { BottomNav } from '../BottomNav';
import { Page } from '../Page';
import { AppContainerProps } from './props';
import { Toaster } from 'react-hot-toast';

const whitelistRoute = [
  '/auth/login',
  '/auth/register',
  '/auth/change-password',
  '/auth/forgot-password',
  '/campaigns',
  '/reward-claims/[id]',
  '/vendor-validator',
];

const fullScreenAuthenticatedRoutes = [
  '/vendor-claims/[id]',
  '/auth/verify-phone-number',
];

const authAgnosticRoutes = ['/invites'];

export const AppContainer = ({
  component: Component,
  ...pageProps
}: AppContainerProps) => {
  const { getCurrentUser, currentUser } = useAuth();
  const [hasCheckedAuth, setHasCheckedAuth] = useState(true);
  const router = useRouter();

  const canRenderBottomNav =
    currentUser && !fullScreenAuthenticatedRoutes.includes(router.pathname);

  const routeProtection = async () => {
    const user = await getCurrentUser();
    setHasCheckedAuth(false);

    if (authAgnosticRoutes.includes(router.pathname)) {
      return;
    }

    if (!user && !whitelistRoute.includes(router.pathname)) {
      if (hasCheckedAuth) {
        router.push({
          pathname: '/auth/login',
          query: { redirect: router.asPath },
        });
      } else {
        router.push('/auth/login');
      }
      return;
    }

    if (user && !user.isPhoneNumberVerified) {
      const baseUrl = '/auth/validate-sms-code';
      if (router.query.redirect) {
        router.push(
          `${baseUrl}?redirect=${encodeURIComponent(
            router.query.redirect.toString()
          )}`
        );
      } else {
        router.push(`${baseUrl}`);
      }

      return;
    }

    if (user && whitelistRoute.includes(router.pathname)) {
      if (!router.query.redirect) {
        router.push('/');
      }
      return;
    }
  };

  useEffect(() => {
    routeProtection();
  }, [currentUser]);

  if (hasCheckedAuth) {
    return (
      <div
        className={'flex flex-1 flex-col h-screen justify-center items-center'}
      >
        <Loader text="Loading Rastel.io..." />
      </div>
    );
  }

  return (
    <div className={'flex flex-1 flex-col h-full'}>
      <Page>
        <Component {...pageProps} />
        <Toaster />
        {canRenderBottomNav && <BottomNav />}
      </Page>
    </div>
  );
};
