import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  makeVar,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getToken } from '../utils/utilsToken';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

// computed props
export const currentSelectedParkingId = makeVar<string | null>(null);
export const parkingsInUnlockRadius = makeVar<string[]>([]);
export const currentSelectedVendorId = makeVar<string | null>(null);

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

const authLink = setContext((_, { headers }) => {
  const token = getToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_API_URL,
});

export const createApolloClient = () => {
  if (!!apolloClient) return apolloClient;

  apolloClient = new ApolloClient({
    link: errorLink.concat(authLink).concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Parking: {
          fields: {
            isSelected: {
              read(value, { readField }) {
                const parkingId = readField('id');
                const isSelected = parkingId === currentSelectedParkingId();

                return isSelected;
              },
            },
            isInUnlockRadius: {
              read(value, { readField }) {
                const parkingId = readField('id');
                const isInUnlockRadius = !!parkingsInUnlockRadius().find(
                  (id) => id === parkingId
                );

                return isInUnlockRadius;
              },
            },
          },
        },
        Vendor: {
          fields: {
            isSelected: {
              read(value, { readField }) {
                const vendorId = readField('id');
                const isSelected = vendorId === currentSelectedVendorId();

                return isSelected;
              },
            },
          },
        },
      },
    }),
    connectToDevTools: true,
  });

  return apolloClient;
};
